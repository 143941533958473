import React, {useMemo} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import BarChartIcon from '@mui/icons-material/BarChart';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventIcon from '@mui/icons-material/Event';
import StyledMainGrid from '../../components/StyledMainGrid';
import moment from 'moment';

export default function CompanyHeader({items}) {
  const stats = useMemo(() => {
    let now = moment();
    let expiredCount = 0;
    let expireIn6MonthCount = 0;
    items.forEach((item) => {
      let expDate = moment.unix(item.expiredDate);
      let expDurationMonth = moment.duration(expDate.diff(now)).months();
      if ( expDurationMonth <= 0) {
        ++expiredCount;
      }
      else if ( expDurationMonth <= 6 ) {
        ++expireIn6MonthCount
      }
    });

    return {
      totalCompany: items.length,
      totalExpired: expiredCount,
      totalExpireIn6Month: expireIn6MonthCount
    };
  }, [items]);

  const typographySx = {
    textAlign: 'center',
    paddingTop: '10px',
    paddingBottom: '10px'
  };
  const iconContainerSx = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '5px'
  };
  return (
    <StyledMainGrid item container columnSpacing>
      <Grid item xs="4">
        <Typography variant="h6" sx={typographySx}>
          <Box sx={iconContainerSx}>
            <BarChartIcon  fontSize="large"/>
          </Box>
          <Box sx={iconContainerSx}>
            Total Companies
          </Box>
          <Box sx={iconContainerSx}>
            {stats.totalCompany}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs="4">
        <Typography variant="h6" sx={typographySx}>
          <Box sx={iconContainerSx}>
            <EventBusyIcon  fontSize="large"/>
          </Box>
          <Box sx={iconContainerSx}>
            Expired License
          </Box>
          <Box sx={{...iconContainerSx, color: '#D9534F'}}>
            {stats.totalExpired}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs="4">
        <Typography variant="h6" sx={typographySx}>
          <Box sx={iconContainerSx}>
            <EventIcon  fontSize="large"/>
          </Box>
          <Box sx={iconContainerSx}>
            Expired in 6 Months
          </Box>
          <Box sx={{...iconContainerSx, color: '#F59C1A'}}>
            {stats.totalExpireIn6Month}
          </Box>
        </Typography>
      </Grid>
    </StyledMainGrid>
  );
}
