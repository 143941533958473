import inMemoryJwt from './inMemoryJwtService';

const inMemoryUser = () => {
  const isAdmin = () => {
    let tokenPayload = inMemoryJwt.getTokenPayload();

    if (tokenPayload['user_role'] === 'administrator') return true;
    else return false;
  };

  const isUser = () => {
    let tokenPayload = inMemoryJwt.getTokenPayload();

    if (tokenPayload['user_role'] !== 'administrator') return true;
    else return false;
  };

  const getUserRole = () => {
    let tokenPayload = inMemoryJwt.getTokenPayload();

    return tokenPayload['user_role'] ? tokenPayload['user_role'] : false;
  };

  const getUsername = () => {
    let tokenPayload = inMemoryJwt.getTokenPayload();

    return tokenPayload['username'];
  };

  const isTest = () => {
    let tokenPayload = inMemoryJwt.getTokenPayload();
    return !!tokenPayload['is_test'];
  };

  return {
    isAdmin,
    isUser,
    getUsername,
    getUserRole,
    isTest
  };
};

export default inMemoryUser();
