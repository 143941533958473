import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import StyledMainGrid from '../../components/StyledMainGrid';

export default function CompanySearch(props) {
  const handleFilterChange = (event) => {
    props.setSearchFilter(event.target.value);
    props.setSearchTerm('');
  };

  let filterOption = props.searchFilters.find(s => props.searchFilter === s.value);
  return (
    <StyledMainGrid item container columnSpacing>
      <Grid item xs="12" sm="6">
        {
          filterOption && filterOption.items ?
          <Autocomplete
            freeSolo
            autoHighlight
            options={filterOption.items}
            onChange={(event, newValue) => props.setSearchTerm(newValue || '')}
            value={props.searchTerm}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                size="small"
                id="search-input"
                type="text"
                label="Search"
                placeholder="Search"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
          :
          <Autocomplete
            freeSolo
            autoHighlight
            options={[]}
            inputValue={props.searchTerm}
            onInputChange={(event, newInputValue) => props.setSearchTerm(newInputValue || '')}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                size="small"
                id="search-input"
                type="text"
                label="Search"
                placeholder="Search"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            )}
            ListboxProps={{
              sx: { display: 'none' }
            }}
          />
        }
      </Grid>
      <Grid item xs="12" sm="6">
        <TextField
          fullWidth
          select
          size="small"
          id="search-select"
          label="Search Filter"
          InputLabelProps={{ shrink: true }}
          value={props.searchFilter}
          onChange={handleFilterChange}
          startAdornment={
            <InputAdornment position="start">
              <FilterAltOutlinedIcon />
            </InputAdornment>
          }
        >
          {props.searchFilters.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </StyledMainGrid>
  );
}
