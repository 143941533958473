import React from 'react';
import AdminPassword from './AdminPassword';
import AdminInstancePassword from './AdminInstancePassword';
import Page from '../../components/Page';
import Box from '@mui/material/Box';

export default function AdminSettings(props) {
  return (
    <Page title="Settings">
      <Box>
        <AdminPassword />
      </Box>
      <Box sx={{ mt: '25px' }}>
        <AdminInstancePassword />
      </Box>
    </Page>
  );
}
