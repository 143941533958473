
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Page from '../components/Page';
import LoadingSpinner from '../components/LoadingSpinner';
import StyledCard from '../components/StyledCard'
import StyledTable from '../components/StyledTable';
import StyledMainGrid from '../components/StyledMainGrid';
import StyledCardFormContent from '../components/StyledCardFormContent';
import { ajaxGetMACAddressList } from '../services/macAddressService';

const columnProps = {
  head: { sx: { fontSize: '12px' } },
  cell: { sx: { fontSize: '12px' } }
};

const columns = [
  { key: 'name',    label: 'Company', props: columnProps, toText: (d) => d.name },
  { key: 'mac',     label: 'MAC',     props: columnProps, toText: (d) => d.mac },
  { key: 'address', label: 'Address', props: columnProps, toText: (d) => d.address || '-'},
  { key: 'status',  label: 'Status',  props: columnProps, toText: (d) => d.status || '-' },
  { key: 'type',    label: 'Type',    props: columnProps, toText: (d) => d.type || '-' },
];


export default function MACAddress(props) {
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    setIsLoading(true);
    ajaxGetMACAddressList()
      .then((res) => {
        const { data } = res;
        setItems(data);
        setIsLoading(false);
      })
      .catch(() => {
        setItems([]);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const results = items.filter((item) => {
      const searchCols = ['name', 'mac', 'address', 'status', 'type'];

      const content = columns.filter(c => searchCols.indexOf(c.key) !== -1)
        .reduce((res, col) => res + col.toText(item) + '#', '');

      return content.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setSearchResults(results);
  }, [searchTerm, items]);

  const searchChangeHandle = (event) => {
    const new_value = event.target.value.toLowerCase();
    setSearchTerm(new_value);
  };

  const renderItem = (data, col, idx, colIdx, secondarySx) => {
    let content = col.toText ? col.toText(data) : data[col.key];
    return content;
  };

  return (
    <Page title="MAC Address" maxWidth="xl">
      <StyledCard>
        <StyledCardFormContent sx={{ mb: { sm: '-72px' } }}>
          <StyledMainGrid>
            <Grid xs={12} item>
              <TextField
                disabled={isLoading}
                fullWidth
                size="small"
                type="text"
                label="Search"
                placeholder="Search keyword"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                onChange={searchChangeHandle}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </Grid>
          </StyledMainGrid>
        </StyledCardFormContent>
        <StyledCardFormContent>
          {
            isLoading ?
            <Box sx={{ height: '50px', mt: '60px', position: 'relative' }}>
              <LoadingSpinner />
            </Box>
            :
            <StyledTable
              dataSource={searchResults}
              pagination={25}
              renderItem={renderItem}
              columns={columns}
              sort={{
                sort: 'asc',
                sortKey: 'name',
              }}
              border
            />
          }
        </StyledCardFormContent>
      </StyledCard>
    </Page>
  );
}
