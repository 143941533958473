import Noty from 'noty';

export function strToNum(str) {
  return Number(str.replace(/\D/g, ''));
}

export const toDateText = (unixTime, isUTCformat) => {
  return new Date((unixTime + (isUTCformat ? (new Date()).getTimezoneOffset() * 60 : 0) ) * 1000).toLocaleDateString();
};

export const toDateTimeText = (unixTime, isUTCformat) => {
  return new Date((unixTime + (isUTCformat ? (new Date()).getTimezoneOffset() * 60 : 0) ) * 1000).toLocaleString();
};

Noty.overrideDefaults({
  theme: 'relax',
  callbacks: {
    onTemplate: function() {
      this.layoutDom.style.top = '60px';
      this.barDom.style.padding = '5px';
      switch (this.options.type) {
        case 'warning':
          this.barDom.style.backgroundColor = '#FFEAA8';
          this.barDom.style.borderColor = '#FFC237';
          this.barDom.style.color = '#826200';
          break;
        case 'error':
        case 'alert':
          this.barDom.style.backgroundColor = '#f2dede';
          this.barDom.style.borderColor = '#ebccd1';
          this.barDom.style.color = '#a94442';
          break;
        case 'success':
          this.barDom.style.backgroundColor = '#BCF5BC';
          this.barDom.style.borderColor = '#7cdd77';
          this.barDom.style.color = 'darkgreen';
          break;
        default:
          this.barDom.style.backgroundColor = '#d9edf7';
          this.barDom.style.borderColor = '#bce8f1';
          this.barDom.style.color = '#31708f';
          break;
      }
    }
  }
});

export function noty( msg, type, onClose ) {
  let timeout = false;
  let text = msg
  if ( type === 'success' ) {
    timeout = 15000;
    text = `
      <div style="display: flex; align-items: center;">
        <svg
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          data-testid="CheckIcon"
          style="
            user-select: none;
            width: 24px;
            height: 24px;
            display: inline-block;
            fill: currentcolor;
            flex-shrink: 0;
            font-size: inherit;
            margin-right: 10px;
        ">
          <path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
        </svg>
        ${msg}
      </div>
    `;
  }
  else if ( type === 'warning' ) {
    text = `
      <div style="display: flex; align-items: center;">
        <svg
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          data-testid="WarningAmberIcon"
          style="
            user-select: none;
            width: 24px;
            height: 24px;
            display: inline-block;
            fill: currentcolor;
            flex-shrink: 0;
            font-size: inherit;
            margin-right: 10px;
        ">
          <path d="M12 5.99 19.53 19H4.47L12 5.99M12 2 1 21h22L12 2z"></path>
          <path d="M13 16h-2v2h2zm0-6h-2v5h2z"></path>
        </svg>
        ${msg}
      </div>
    `;
  }
  else if ( type === 'error' ) {
    text = `
      <div style="display: flex; align-items: center;">
        <svg
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          data-testid="ErrorOutlineIcon"
          style="
            user-select: none;
            width: 24px;
            height: 24px;
            display: inline-block;
            fill: currentcolor;
            flex-shrink: 0;
            font-size: inherit;
            margin-right: 10px;
        ">
          <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
        </svg>
        ${msg}
      </div>
    `;
  }
  let n = new Noty({
    text: text,
    layout: 'topCenter',
    type: type,
    buttons: false,
    closeWith: ['click'],
    timeout: timeout,
    callbacks: {
      onClose: onClose
    }
  });
  n.show();
  return n;
}

