import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import LoadingSpinner from '../../components/LoadingSpinner';
import StyledMainGrid from '../../components/StyledMainGrid';
import StyledTable from '../../components/StyledTable';
import Page from '../../components/Page';
import StyledCard from '../../components/StyledCard'
import StyledCardFormContent from '../../components/StyledCardFormContent';
import CompanySearch from './CompanySearch';
import CompanyHeader from './CompanyHeader';
import {toDateText} from '../../helpers';
import {ajaxGetCompanyList} from '../../services/companyService';
import moment from 'moment';

const searchFilters = [
  {
    value: 'name',
    label: 'Company'
  },
  {
    value: 'email',
    label: 'Email'
  },
  {
    value: 'instance',
    label: 'Instance'
  }
];

const columnProps = {
  head: { sx: { fontSize: '12px' } },
  cell: { sx: { fontSize: '12px' } }
};
const licenseColProps = {
  head: { align: 'right', sx: { width: '100px', fontSize: '12px' } },
  headText: { style: { textAlign: 'center' } },
  cell: { align: 'right', sx: { fontSize: '12px' } }
};
const editColProps = {
  head: { sx: { width: '60px', fontSize: '12px' } },
  headText: { style: { textAlign: 'center' } },
  cell: { align: 'right', sx: { fontSize: '12px' } }
};

const toLicenseText = (available, max) => {
  let avaiText = '-';
  let maxText = '-';
  if ( typeof available !== 'undefined' ) {
    avaiText = available;
  }
  if ( typeof max !== 'undefined' ) {
    maxText = max;
  }

  if ( available === 0 && max === 0 ) {
    return '-';
  }
  return `${avaiText} / ${maxText}`;
};

const columns = [
  { key: 'name',  label: 'Company',   props: columnProps, toText: (d) => d.name },
  { key: 'email', label: 'Email',     props: columnProps, toText: (d) => d.props.email || '-' },
  //{ key: 'lastLogin', label: 'Last Login',   props: columnProps, toText: (d) => d.lastLogin || '-' },
  { key: 'lastLoginDate', label: 'Last Login', props: licenseColProps, toText: (d) => d.lastLogin ? toDateText(d.lastLogin) : '-', toSort: (d) => d.lastLogin },
  { key: 'expire',label: 'Expiration',props: licenseColProps, toText: (d) => toDateText(d.expiredDate), toSort: (d) => d.expiredDate },
  { key: 'host',  label: 'Host1', props: licenseColProps, toText: (d) => toLicenseText(d.usageData.HOST, d.limitsData.HOST) },
  { key: 'sp2',   label: 'Host2', props: licenseColProps, toText: (d) => toLicenseText(d.usageData.SP2, d.limitsData.SP2) },
  { key: 'exp',   label: 'Expansion',  props: licenseColProps, toText: (d) => toLicenseText(d.usageData.BOARD, d.limitsData.BOARD) },
  { key: 'vsen',  label: 'VSensor  ',  props: licenseColProps, toText: (d) => toLicenseText(d.usageData.VIRTUAL_SENSOR, d.limitsData.VIRTUAL_SENSOR) },
  { key: 'dvsen', label: 'Device VSensor', props: licenseColProps, toText: (d) => toLicenseText(d.usageData.DEVICE_VIRTUAL_SENSOR, d.limitsData.DEVICE_VIRTUAL_SENSOR) },
  { key: 'cam',   label: 'IP Camera',  props: licenseColProps, toText: (d) => toLicenseText(d.usageData.CAMERA, d.limitsData.CAMERA) },
  /*{ key: 'cfd',   label: 'Free CFD',  props: licenseColProps, toText: (d) => d.limitsData.CFD_FREE || '-' },
  { key: 'pcfd',  label: 'Paid CFD',    props: licenseColProps, toText: (d) => d.limitsData.CFD || '-' },*/
  { key: 'instance',label: '',          props: editColProps , toText: (d) => d.instance, sort: false }
];

export default function Company(props) {
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFilter, setSearchFilter] = useState('name');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getCompanyList = () => {
    setIsLoading(true);
    ajaxGetCompanyList()
      .then((res) => {
        const { data } = res;
        setItems(data);
        setIsLoading(false);
      })
      .catch(() => {
        setItems([]);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getCompanyList();
  }, []);

  useEffect(() => {
    let colData = columns.filter(c => c.key === searchFilter).reduce((sum, cur) => cur, ({}));
    const results = items.filter((item) => {
      let content = '';
      if (colData) {
        content = colData.toText ? colData.toText(item) : item[colData.key];
      }
      return content.toLowerCase().includes(searchTerm.toLowerCase());
    });

    setSearchResults(results);
  }, [searchTerm, searchFilter, items]);

  const renderItem = (data, col, idx, colIdx, secondarySx) => {
    let content = col.toText ? col.toText(data) : data[col.key];
    let now = moment();
    if ( col.key === 'expire' ) {
      let expDate = moment.unix(data.expiredDate);
      let expDuration = moment.duration(expDate.diff(now));
      let expDurationMonth = expDuration.asMonths();
      content = (
        <Tooltip
          title={`Expired ${expDuration.humanize(true)}`}
        >
          <span
            style={{
              color: (
                !data.expiredDate ||
                expDurationMonth <= 0 ?
                '#D9534F'
                :
                expDurationMonth <= 6 ?
                '#F59C1A'
                :
                undefined
              ),
              fontWeight: (
                expDurationMonth <= 6 ?
                'bold'
                :
                undefined
              )
            }}
          >
            {col.toText(data)}
          </span>
        </Tooltip>
      );
    }
    else if ( col.key === 'lastLoginDate' ) {
      let lastLoginDate = moment.unix(data.lastLogin);
      let lastLoginDuration = moment.duration(lastLoginDate.diff(now));
      let lastLoginDurationDay = lastLoginDuration.asDays();
      content = (
        <Tooltip title={data.lastLogin ? `Inactive for ${lastLoginDuration.humanize()}` : undefined}>
          <span
            style={{
              color: (
                !data.lastLogin ?
                undefined
                :
                lastLoginDurationDay <= 30 ?
                '#D9534F'
                :
                lastLoginDurationDay <= 7 ?
                '#F59C1A'
                :
                undefined
              ),
              fontWeight: (
                lastLoginDurationDay <= 7 ?
                'bold'
                :
                undefined
              )
            }}
          >
            {col.toText(data)}
          </span>
        </Tooltip>
        );
    }
    else if ( col.key === 'instance' ) {
      content = (
        <Tooltip title={content}>
          <Button
            variant="contained"
            size="normal"
            sx={{
              minWidth: 'unset',
              height: '20px',
              fontSize: '10px',
              padding: '6px 12px'
            }}
            rel="noopener"
            target="_blank"
            href={
              content.includes('http://') ||
              content.includes('https://') ?
              `${content}/login.html?c=${data.id}`
              :
              `//${content}/login.html?c=${data.id}`
            }
          >
            Edit
          </Button>
        </Tooltip>
      );
    }
    return content;
  };

  return (
    <Page title="Company" maxWidth="xl">
      <StyledCard>
        <StyledCardFormContent sx={{ mb: { sm: '-72px' } }}>
          <StyledMainGrid>
            <Grid xs={12} item>
              <CompanySearch
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                setSearchFilter={setSearchFilter}
                searchFilter={searchFilter}
                searchFilters={searchFilters.map(s => (
                  s.value === 'name' ?
                  {...s, items: items.map(i => i.name)}
                  :
                  s.value === 'instance' ?
                  {...s, items: items.map(i => i.instance).reduce((sum, cur) => [...sum.filter(i => i !== cur), cur], [])}
                  :
                  {...s}
                ))}
              />
            </Grid>
            <Grid xs={12} item>
              <CompanyHeader items={searchResults} />
            </Grid>
          </StyledMainGrid>
        </StyledCardFormContent>
        <StyledCardFormContent>
          {
            isLoading ?
            <Box sx={{ height: '50px', mt: '60px', position: 'relative' }}>
              <LoadingSpinner />
            </Box>
            :
            <StyledTable
              dataSource={searchResults}
              pagination={25}
              renderItem={renderItem}
              columns={columns}
              sort={{
                sort: 'asc',
                sortKey: 'expire',
              }}
              border
            />
          }
        </StyledCardFormContent>
      </StyledCard>
    </Page>
  );
}
