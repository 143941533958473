import { ajaxCall, formRequestData } from './ajaxService';

const constants = window.getConfig;
const getAdminChangePwdUrl = `${constants.ajax.baseUrl}/admin/pwd`;
const getAdminChangeInstancePwdUrl = `${constants.ajax.baseUrl}/admin/instancePwd`;
const getAdminGetSettingsUrl = `${constants.ajax.baseUrl}/admin/settings`;

export const ajaxSaveAdminPwd = (params) =>
  new Promise((res, rej) => {
    if (params.username && params.password) {
      ajaxCall(getAdminChangePwdUrl, formRequestData(constants.ajax.methods.post, true, false, params)).then(res).catch(rej);
    } else {
      res();
    }
  });

export const ajaxSaveInstancePwd = (params) =>
  new Promise((res, rej) => {
    if (params.username || params.password) {
      ajaxCall(getAdminChangeInstancePwdUrl, formRequestData(constants.ajax.methods.post, true, false, params)).then(res).catch(rej);
    } else {
      res();
    }
  });

export const ajaxGetSettings = () =>
  new Promise((res, rej) => {
    ajaxCall(getAdminGetSettingsUrl, formRequestData(constants.ajax.methods.get, true, false)).then(res).catch(rej);
  });
