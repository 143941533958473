import AdminSettings from '../pages/AdminSettings/AdminSettings';
import Instance from '../pages/Instance/Instance';
import Company from '../pages/Company/Company';
import MACAddress from '../pages/MACAddress'

export const adminRoutes = [
  { path: '/settings', element: AdminSettings, exact: true },
  { path: '/instance', element: Instance, exact: true },
  { path: '/company', element: Company, exact: true },
  { path: '/macaddress', element: MACAddress, exact: true }
];

export const userRoutes = [
  { path: '/company', element: Company, exact: true },
  { path: '/macaddress', element: MACAddress, exact: true }
];
