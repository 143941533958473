import React, {useMemo, useState, useEffect} from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Pagination from '@mui/material/Pagination';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    /*backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,*/
    padding: '5px'
  },
  /*[`&.${tableCellClasses.head} .Mui-active`]: {
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.head} .Mui-active .MuiTableSortLabel-icon`]: {
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.head} .MuiTableSortLabel-root:hover`]: {
    color: theme.palette.common.white
  },*/
  [`&.${tableCellClasses.body}`]: {
    padding: '5px'
  },
  [`&.${tableCellClasses.body} td`]: {
    border: 0
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  }
}));

export default function StyledTable({ dataSource, renderItem, columns, sort, pagination, border, sx, ...restProps }) {
  const [sortState, setSortState] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);

  useEffect(() => {
    if ( sort ) {
      setSortState({
        sortKey: sort.sortKey,
        sort: sort.sort || 'asc'
      });
    }
  }, [sort]);

  const sortParams = useMemo(() => {
    if ( !sort ) {
      return false;
    }

    let returnSortParams = {};
    if ( sortState && sortState.sortKey ) {
      returnSortParams.sortKey = sortState.sortKey;
    }
    if ( sortState && sortState.sort ) {
      returnSortParams.sort = sortState.sort;
    }

    if ( Object.keys(returnSortParams).length <= 0 ) {
      return false;
    }

    return {...returnSortParams};
  },
  [sort, sortState]);

  const onSortClick = (sortParams) => {
    setSortState({...sortParams});
  };

  const onPageChange = (event, value) => {
    setPageIndex(value);
  };

  let displayDataSource = dataSource ? [...dataSource] : [];
  if ( sortParams ) {
    let colData = columns.filter(c => c.key === sortParams.sortKey).reduce((sum, cur) => cur, ({}));
    displayDataSource = displayDataSource.sort((a, b) => {
      let aComp = a;
      let bComp = b;
      if (colData) {
        aComp = colData.toSort ? colData.toSort(aComp) : colData.toText ? colData.toText(aComp) : aComp[colData.key];
        bComp = colData.toSort ? colData.toSort(bComp) : colData.toText ? colData.toText(bComp) : bComp[colData.key];
      }
      if ( aComp > bComp ) {
        return sortParams.sort === 'desc' ? -1 : 1;
      }
      else if ( aComp < bComp ) {
        return sortParams.sort === 'desc' ? 1 : -1;
      }
      return 0;
    });
  }

  let pageCount = 1;
  let pageItemCount = 25;
  if ( pagination ) {
    pageItemCount = parseInt(pagination, 10);
    if ( isNaN(pageItemCount) ) {
      pageItemCount = 25;
    }
    pageCount = Math.ceil(displayDataSource.length / pageItemCount) || 1; // round up
    displayDataSource = displayDataSource.slice(((pageIndex - 1) * pageItemCount), pageIndex * pageItemCount)
  }

  const secondarySx = { fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' };
  return (
    <React.Fragment>
      <TableContainer>
        <Table
          size="small"
          sx={{
            ...sx,
            border: (border ? '1px solid rgb(224, 224, 224)' : undefined)
          }}
          {...restProps}
        >
          <TableHead>
            <TableRow
              sx={{
                "& th": {
                  borderRight: border ? '1px solid rgb(224, 224, 224)' : undefined
                },
                "& th:last-child": {
                  borderRight: border ? '1px solid rgb(224, 224, 224)' : undefined
                }
              }}
            >
              {columns.map((headCell) => {
                let sort = sortParams && headCell.key === sortParams.sortKey ? sortParams.sort : ( headCell.sort === false ? false : 'desc' );
                return (
                  <StyledTableCell
                    key={headCell.key}
                    sortDirection={sort ? sort : undefined}
                    padding="none"
                    {...(headCell.props && headCell.props.head ? headCell.props.head : {})}
                  >
                    {
                      sortParams ?
                      <TableSortLabel
                        hideSortIcon={!sort}
                        active={sort && sortParams.sortKey === headCell.key}
                        direction={sort ? sort : undefined}
                        onClick={
                          !sort ?
                          undefined
                          :
                          sortParams.sortKey === headCell.key ?
                          (
                            sort === 'asc' ?
                            () => onSortClick({...sortParams, sort: 'desc'})
                            :
                            () => onSortClick({...sortParams, sort: 'asc'})
                          )
                          :
                          () => onSortClick({...sortParams, sort: 'asc', sortKey: headCell.key})
                        }
                      >
                        <span {...(headCell.props && headCell.props.headText ? headCell.props.headText : {})}>
                          {headCell.label}
                        </span>
                      </TableSortLabel>
                      :
                      <span {...(headCell.props && headCell.props.headText ? headCell.props.headText : {})}>
                        {headCell.label}
                      </span>
                    }
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              displayDataSource &&
              displayDataSource.length > 0 &&
              displayDataSource.map((data, idx) => {
                return (
                  <StyledTableRow
                    key={idx}
                    sx={{
                      "& td": {
                        borderRight: border ? '1px solid rgb(224, 224, 224)' : undefined
                      },
                      "& td:last-child": {
                        borderRight: border ? '1px solid rgb(224, 224, 224)' : undefined
                      }
                    }}
                  >
                    {
                      columns.map((col, colIdx) => {
                        let content = data[col.key] ? data[col.key] : '';
                        if ( typeof renderItem === 'function' ) {
                          content = renderItem(data, col, idx, colIdx, secondarySx);
                        }
                        return (
                          <StyledTableCell
                            key={col.key}
                            padding="none"
                            {...(col.props && col.props.cell ? col.props.cell : {})}
                          >
                            {content}
                          </StyledTableCell>
                        );
                      })
                    }
                  </StyledTableRow>
                );
              })
            }
            {
              (!displayDataSource || displayDataSource.length <= 0) &&
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No Items
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      {
        pagination &&
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
          <Pagination page={pageIndex} count={pageCount} onChange={onPageChange} color="secondary" />
        </div>
      }
    </React.Fragment>
  );
}
