import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import validator from 'validator';
import LoadingSpinner from '../../components/LoadingSpinner';
import {noty} from '../../helpers';
import {
    ajaxGetInstanceItem,
    ajaxAddInstanceItem,
    ajaxUpdateInstanceItem
} from '../../services/instanceService';

export default function InstanceDialog({ onHide, show, instanceId }) {
  const sx = {
    button: {
      padding: '4px 30px'
    }
  };

  const initialFieldsValues = { instance: '', username: '', password: '' };
  const [fields, setFields] = useState({ ...initialFieldsValues });
  const [errors, setErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const onHideHandler = (params) => {
    setErrors(false);
    setFields({ ...initialFieldsValues });
    onHide(params);
  };

  const handleFieldChange = (event) => {
    const value = event.target.value;
    setFields({
      ...fields,
      [event.target.name]: value
    });
  };

  const getError = (fieldId) => {
    return errors && errors[fieldId];
  };

  const checkError = (fieldId) => {
    return errors && errors[fieldId] ? true : false;
  };

  const validateFields = () => {
    let hasError = false;
    let newErrors = {};
    let instanceKey = 'instance';
    let instanceValue = fields[instanceKey] ? fields[instanceKey] + '' : '';
    if (validator.isEmpty(instanceValue, { ignore_whitespace: true })) {
      newErrors[instanceKey] = 'required field';
      hasError = true;
    }
    
    let instancePwdKey = 'password';
    let instancePwdConfirmKey = 'confirmPassword';
    let instancePwdValue = fields[instancePwdKey] ? fields[instancePwdKey] + '' : '';
    let instancePwdConfirmValue = fields[instancePwdConfirmKey] ? fields[instancePwdConfirmKey] + '' : '';
    if (instancePwdValue !== instancePwdConfirmValue) {
      newErrors[instancePwdConfirmKey] = 'Please enter the same value again.';
      hasError = true;
    }

    if (hasError) {
      setErrors(newErrors);
    }

    return !hasError;
  };

  const handleSave = () => {
    if (validateFields()) {
      let params = {
        instance: fields.instance,
        username: fields.username,
        password: fields.password
      };

      if ( instanceId !== -1 ) {
        params.id = instanceId;
      }

      (
        instanceId === -1 ?
        ajaxAddInstanceItem(params)
        :
        ajaxUpdateInstanceItem(params)
      )
        .then((res) => {
          if ( !res.error ) {
            onHideHandler(true);
          }
        })
        .catch((e) => {
          noty(e.error, 'error');
        });
    }
  };

  useEffect(() => {
    if ( instanceId === -1 ) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    ajaxGetInstanceItem({ id: instanceId })
        .then((res) => {
          if ( !res.error ) {
            setFields({...res.data});
          }
          setIsLoading(false);
        })
        .catch((e) => {
          noty(e.error, 'error');
          setIsLoading(false);
        });
  }, [instanceId, show]);

  return (
    <Dialog
      fullWidth
      PaperProps={{ style: { backgroundColor: 'white', color: 'black' } }}
      onClose={() => onHideHandler(false)}
      open={show}
    >
      <DialogTitle>
        {
          instanceId === -1 ?
          'Add Instance'
          :
          'Edit Instance'
        }
      </DialogTitle>
      {
        isLoading ?
        <DialogContent>
          <Box sx={{ height: '50px', mt: '30px', position: 'relative' }}>
            <LoadingSpinner />
          </Box>
        </DialogContent>
        :
        <React.Fragment>
          <DialogContent>
            <DialogContentText color="inherit">
              Enter instance information
            </DialogContentText>
            <TextField
              sx={{ mt: '15px' }}
              error={checkError('instance')}
              helperText={getError('instance')}
              id="instance"
              name="instance"
              label="Instance URL"
              type="text"
              onChange={handleFieldChange}
              value={fields['instance']}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            <TextField
              sx={{ mt: '15px' }}
              error={checkError('username')}
              helperText={getError('username')}
              id="username"
              name="username"
              label="Instance Username"
              placeholder="leave blank to use default username/password from settings"
              type="text"
              onChange={handleFieldChange}
              value={fields['username']}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            <TextField
              sx={{ mt: '15px' }}
              error={checkError('password')}
              helperText={getError('password')}
              id="password"
              name="password"
              label="Instance Password"
              placeholder="leave blank to not change the password"
              type="password"
              onChange={handleFieldChange}
              value={fields['password']}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            <TextField
              sx={{ mt: '15px' }}
              error={checkError('confirmPassword')}
              helperText={getError('confirmPassword')}
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Instance Password"
              automComplete="confirmPassword"
              type="password"
              onChange={handleFieldChange}
              value={fields['confirmPassword']}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </DialogContent>
          <DialogActions style={{ flex: 'space-around' }}>
            <Button onClick={handleSave} sx={sx.button} variant="contained">
              Save
            </Button>
            <Button onClick={() => onHideHandler(false)} sx={sx.button} variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </React.Fragment>
      }
    </Dialog>
  );
}

InstanceDialog.defaultProps = {
  instanceId: -1,
  show: false,
  onHide: () => {}
};
