import { ajaxCall, formRequestData } from './ajaxService';

const constants = window.getConfig;
const getInstanceListUrl = `${constants.ajax.baseUrl}/admin/instance`;
const getInstanceItemUrl = `${constants.ajax.baseUrl}/admin/instance/item`;
const getInstanceAddUrl = `${constants.ajax.baseUrl}/admin/instance/add`;
const getInstanceUpdateUrl = `${constants.ajax.baseUrl}/admin/instance/update`;
const getInstanceDeleteUrl = `${constants.ajax.baseUrl}/admin/instance/delete`;
const getInstanceFetchUrl = `${constants.ajax.baseUrl}/admin/instance/fetch`;

export const ajaxGetInstanceList = () =>
  new Promise((res, rej) => {
    ajaxCall(getInstanceListUrl, formRequestData(constants.ajax.methods.get, true, false)).then(res).catch(rej);
  });

export const ajaxGetInstanceItem = (params) =>
  new Promise((res, rej) => {
    ajaxCall(`${getInstanceItemUrl}/${params.id}`, formRequestData(constants.ajax.methods.get, true, false)).then(res).catch(rej);
  });

export const ajaxAddInstanceItem = (params) =>
  new Promise((res, rej) => {
    ajaxCall(`${getInstanceAddUrl}`, formRequestData(constants.ajax.methods.post, true, false, params), params.ignoreError).then(res).catch(rej);
  });
  
export const ajaxUpdateInstanceItem = (params) =>
  new Promise((res, rej) => {
    ajaxCall(`${getInstanceUpdateUrl}/${params.id}`, formRequestData(constants.ajax.methods.post, true, false, params), params.ignoreError).then(res).catch(rej);
  });

export const ajaxDeleteInstanceItem = (params) =>
  new Promise((res, rej) => {
    ajaxCall(`${getInstanceDeleteUrl}/${params.id}`, formRequestData(constants.ajax.methods.post, true, false, params), params.ignoreError).then(res).catch(rej);
  });

export const ajaxFetchInstanceList = (params) =>
  new Promise((res, rej) => {
    ajaxCall(`${getInstanceFetchUrl}/${params.id}`, formRequestData(constants.ajax.methods.get, true, false, params), params.ignoreError).then(res).catch(rej);
  });