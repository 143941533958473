import React, { useState, useEffect } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import KeyIcon from '@mui/icons-material/Key';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import StyledMainGrid from '../../components/StyledMainGrid';
import StyledList from '../../components/StyledList';
import Page from '../../components/Page';
import StyledCard from '../../components/StyledCard'
import StyledCardFormContent from '../../components/StyledCardFormContent';
import ConfirmationButton from '../../components/ConfirmationButton';
import LoadingSpinner from '../../components/LoadingSpinner';
import {noty} from '../../helpers';
import InstanceSearch from './InstanceSearch';
import InstanceDialog from './InstanceDialog';
import {
  ajaxGetInstanceList,
  ajaxDeleteInstanceItem
} from '../../services/instanceService';

const searchFilters = [
  {
    value: 'instance',
    label: 'Instance'
  }
];

export default function Instance(props) {
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFilter, setSearchFilter] = useState('instance');
  const [searchResults, setSearchResults] = useState([]);
  const [openInstanceDialog, setOpenInstanceDialog] = useState(false);
  const [seletedInstanceId, setSeletedInstanceId] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);

  const getInstanceList = () => {
    setIsLoading(true);
    ajaxGetInstanceList()
      .then((res) => {
        const { data } = res;
        setItems(data.map(d => ({
          ...d,
          status: (
            d.auth ?
            'authorized'
            :
            d.online ?
            'online'
            :
            'unreachable'
          )
        })));
        setIsLoading(false);
      })
      .catch(() => {
        setItems([]);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getInstanceList();
  }, []);

  useEffect(() => {
    const results = items.filter((item) => {
      let testText = true;
      if (item[searchFilter]) {
        testText = item[searchFilter].toLowerCase().includes(searchTerm);
      }
      return testText;
    });

    setSearchResults(results);
  }, [searchTerm, searchFilter, items]);

  const onInstanceDialogClose = (ret) => {
    setOpenInstanceDialog(false);
    setSeletedInstanceId(-1);
    if (ret) {
      getInstanceList();
    }
  };

  const handleDelete = (id) => {
    ajaxDeleteInstanceItem({ id })
      .then((res) => {
        if ( !res.error ) {
          getInstanceList();
        }
      })
      .catch((e) => {
        noty(e.error, 'error');
      });
  };

  const renderItem = (data, idx, secondarySx) => {
    return (
      <ListItem
        key={idx}
        disablePadding
        secondaryAction={
          <ConfirmationButton
            buttonProps={{
              variant: 'contained',
              style: {
                height: '30px',
                width: '60px',
                fontSize: '12px'
              },
              sx:{
                backgroundColor: '#c62828'
              }
            }}
            handleDialogClickYes={() => handleDelete(data.id)}
            buttonText="Delete"
            dialogText="Are you sure you want to delete this instance?"
            dialogYesText="Confirm"
            dialogNoText="Cancel"
          />
        }
      >
        <ListItemButton onClick={() => { setSeletedInstanceId(data.id); setOpenInstanceDialog(true); }}>
          <ListItemIcon sx={{ mr: '15px' }}>
            {
              data.auth ?
              <Tooltip title="Authorized">
                <KeyIcon color="success"/>
              </Tooltip>
              :
              (
                data.online ?
                <Tooltip title="Unauthorized">
                  <KeyOffIcon color="warning"/>
                </Tooltip>
                :
                <Tooltip title="Offline">
                  <PublicOffIcon color="error" />
                </Tooltip>
              )
            }
          </ListItemIcon>
          <ListItemText
            primary={data.instance}
            secondary={data.username}
          />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <Page title="Instance">
      <StyledCard>
        <StyledCardFormContent sx={{ mb: { sm: '-72px' } }}>
          <StyledMainGrid>
            <Grid xs={12} item>
              <InstanceSearch
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                setSearchFilter={setSearchFilter}
                searchFilter={searchFilter}
                searchFilters={searchFilters.map(s => (
                  s.value !== 'instance' ?
                  {...s}
                  :
                  {...s, items: items.map(i => i.instance)}
                ))}
              />
            </Grid>
            <Grid xs={12} item sx={{ mb: '-60px' }}>
              <Button
                variant="contained"
                size="small"
                sx={{ mr: '10px' }}
                onClick={() => { setSeletedInstanceId(-1); setOpenInstanceDialog(true); }}
              >
                Add
              </Button>
            </Grid>
          </StyledMainGrid>
        </StyledCardFormContent>
        <StyledCardFormContent>
          {
            isLoading ?
            <Box sx={{ height: '50px', mt: '60px', position: 'relative' }}>
              <LoadingSpinner />
            </Box>
            :
            <StyledList
              noEmptyRow
              dataSource={searchResults}
              pagination={25}
              renderItem={renderItem}
              sort={{
                sort: 'desc',
                sortKey: 'status',
                sortOptions: [
                  {
                    key: 'instance',
                    label: 'Instance'
                  },
                  {
                    key: 'status',
                    label: 'Status'
                  }
                ]
              }}
            />
          }
        </StyledCardFormContent>
      </StyledCard>
      {
        openInstanceDialog &&
        <InstanceDialog
          instanceId={seletedInstanceId}
          show={openInstanceDialog}
          onHide={onInstanceDialogClose}
        />
      }
    </Page>
  );
}
